@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$primary_color: #0d5045;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;
$text_font: "DM Sans", sans-serif;

.product-components {
  width: 100%;

  .product-containerr {
    margin-top: 99px;

    .top-div {
      padding: 2rem 0rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary_color;

      .header-container {
        width: 80%;
        display: flex;
        align-items: center;

        .left {
          width: 50%;
          .items {
            display: flex;
            flex-direction: column;
            gap: 26px;
            h3 {
              font-family: $secondary_font;
              font-weight: 700;
              font-size: 64px;
              color: $white_color;
            }
            .links {
              display: flex;
              align-items: center;
              gap: 8px;
              .icons {
                font-size: 24px;
                color: $white_color;
              }
              p {
                margin: 0;
                font-family: $text_font;
                font-size: 18px;
                color: $white_color;
              }
            }
          }
        }
        .right {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
    }

    .product-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .list-head {
        margin-top: 3rem;
        padding: 1rem 0rem;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          margin: 0;
          font-family: $secondary_font;
          font-weight: 700;
          font-size: 52px;
          color: #009541;
        }
      }
      .devider {
        border: 1px solid #aeaeae;
        width: 80%;
      }

      .tabs {
        padding: 1rem 0rem;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 2rem;

        .deactive {
          margin: 0;
          cursor: pointer;
          padding: 0.5rem 1rem;
          font-family: $secondary_font;
          font-weight: 400;
          font-size: 24px;
          color: #898989;
        }
        .active {
          margin: 0;
          cursor: pointer;
          padding: 0.5rem 1rem;
          font-family: $secondary_font;
          font-weight: 500;
          font-size: 24px;
          color: #664444;
          border-bottom: 2px solid #664444;
        }
      }
      .list-items {
        margin-top: 1rem;
        margin-bottom: 8rem;
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;

        .item {
          height: 500px;
          width: 22%;
          .img-box {
            width: 100%;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f4f4;
            border-radius: 10px;
            img {
              height: 310px;
            }
          }

          .details {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 500;
              font-size: 20px;
              color: #664444;
            }
            h6 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 400;
              font-size: 18px;
              color: #664444;
            }
          }

          p {
            margin-top: 4px;
            margin: 0;
            font-family: $text_font;
            font-weight: 400;
            font-size: 18px;
            color: #664444;
          }

          .button {
            display: none;
            text-decoration: none;
            border: none;
            width: 100%;
            height: 60px;
            background: linear-gradient(
              94.67deg,
              #a5f822 1.65%,
              #00ff96 97.53%
            );
            border-radius: 5px;
            font-family: $secondary_font;
            font-weight: 700;
            font-size: 24px;
            color: #0d5045;
          }
        }
        .item:hover {
          .button {
            margin-top: -6px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            visibility: hidden;
          }
        }
      }
      //   .load-more {
      //     margin-top: 2rem;
      //     border: none;
      //     background: none;
      //     width: 308px;
      //     height: 73px;
      //     font-family: $secondary_font;
      //     font-weight: 700;
      //     font-size: 18px;
      //     color: #0d5045;
      //     border: 1px solid #0d5045;
      //     border-radius: 16px;
      //   }
    }
  }
}

@media screen and (max-width: 1440px) {
  .product-components {
    .product-containerr {
      .top-div {
        .header-container {
          .left {
            .items {
              gap: 20px;
              h3 {
                font-size: 50px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .product-list {
        .list-head {
          margin-top: 3rem;
          h3 {
            font-size: 40px;
          }
        }
        .tabs {
          padding: 1rem 0rem;
          width: 80%;
          gap: 2rem;

          .deactive {
            font-size: 20px;
          }
          .active {
            font-size: 20px;
          }
        }
        .list-items {
          width: 80%;
          gap: 30px;

          .item {
            height: 420px;
            .img-box {
              padding: 1.5rem;
              img {
                height: 260px;
              }
            }

            .details {
              margin-top: 16px;
              .name {
                font-size: 16px;
              }
              .tag {
                font-size: 14px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 60px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .product-components {
    .product-containerr {
      .top-div {
        .header-container {
          .left {
            .items {
              gap: 0;
              h3 {
                font-size: 40px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .product-list {
        .list-head {
          margin-top: 3rem;
          h3 {
            font-size: 30px;
          }
        }
        .tabs {
          padding: 1rem 0rem;
          width: 80%;
          gap: 1.5rem;

          .deactive {
            font-size: 16px;
          }
          .active {
            font-size: 18px;
          }
        }
        .list-items {
          width: 80%;
          gap: 30px;
          .item {
            width: 30%;
            height: 450px;
            .img-box {
              padding: 2rem;
              img {
                height: 270px;
              }
            }

            .details {
              margin-top: 16px;
              .name {
                font-size: 16px;
              }
              .tag {
                font-size: 14px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 60px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .product-components {
    .product-containerr {
      .top-div {
        .header-container {
          .left {
            .items {
              h3 {
                font-size: 30px;
              }
              // .links {
              //   .icons {
              //     font-size: 24px;
              //   }
              //   p {
              //     font-size: 18px;
              //   }
              // }
            }
          }
        }
      }

      .product-list {
        .list-head {
          width: 85%;
          margin-top: 3rem;
          h3 {
            font-size: 26px;
          }
        }
        .devider {
          width: 85%;
        }
        .tabs {
          width: 85%;
          gap: 0.5rem;
          .deactive {
            font-size: 15px;
          }
          .active {
            font-size: 15px;
          }
        }
        .list-items {
          width: 85%;
          gap: 30px;
          .item {
            width: 30%;
            height: 440px;
            .button {
              height: 50px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 925px) {
  .product-components {
    .product-containerr {
      .top-div {
        .header-container {
          .left {
            .items {
              h3 {
                font-size: 26px;
              }
              .links {
                .icons {
                  font-size: 18px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .product-list {
        .list-head {
          width: 85%;
          margin-top: 3rem;
          h3 {
            font-size: 20px;
          }
        }
        .devider {
          width: 85%;
        }
        .tabs {
          width: 85%;
          .deactive {
            font-size: 12px;
          }
          .active {
            font-size: 12px;
          }
        }
        .list-items {
          width: 85%;
          gap: 30px;
          .item {
            width: 30%;
            height: 370px;
            .img-box {
              padding: 2rem;
              img {
                height: 210px;
              }
            }

            .details {
              margin-top: 8px;
              .name {
                font-size: 14px;
              }
              .tag {
                font-size: 12px;
              }
            }

            .para {
              font-size: 12px;
            }

            .button {
              height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .product-components {
    .product-containerr {
      .product-list {
        .list-head {
          width: 85%;
          h3 {
            font-size: 18px;
          }
        }
        .devider {
          width: 85%;
        }
        .tabs {
          width: 85%;
          .deactive {
            font-size: 10px;
          }
          .active {
            font-size: 10px;
          }
        }
        .list-items {
          width: 85%;
          gap: 26px;
          .item {
            width: 45%;
            height: 370px;
            .para {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .product-components {
    .product-containerr {
      .product-list {
        .tabs {
          width: 85%;
          gap: 0;
          .deactive {
            font-size: 8px;
          }
          .active {
            font-size: 10px;
          }
        }
        .list-items {
          width: 85%;
          gap: 26px;
          .item {
            width: 45%;
            height: 350px;
            .img-box {
              padding: 2rem;
              img {
                height: 200px;
              }
            }
            .details {
              .name {
                font-size: 12px;
              }
              .tag {
                font-size: 10px;
              }
            }

            .para {
              font-size: 12px;
            }

            .button {
              height: 45px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .product-components {
    .product-containerr {
      .product-list {
        .list-items {
          gap: 30px;
          justify-content: center;
          .item {
            width: 80%;
            .details {
              .name {
                font-size: 14px;
              }
              .tag {
                font-size: 12px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}