@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$primary_color: #0d5045;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;
$text_font: "DM Sans", sans-serif;

.product-details-components {
  width: 100%;

  .product-details-container {
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    .hero-section {
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 80%;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      .left {
        height: 660px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f4f4f4;
        padding: 2rem;
        .product-img {
          height: 100%;
        }
      }
      .right {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        .header {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          font-family: $secondary_font;
          h3 {
            margin: 0;
            font-weight: 700;
            font-size: 52px;
            color: #005f16;
            span {
              font-size: 30px;
            }
          }
          h6 {
            margin: 0;
            font-weight: 500;
            font-size: 36px;
            color: #009541;
          }
        }

        .details {
          .heading-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0rem;

            h6{
              margin: 0;
              font-weight: 600;
              font-size: 26px;
            }
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0rem;
            p {
              margin: 0;
              font-family: $text_font;
              font-size: 20px;
              color: #664444;
            }
          }
        }

        button {
          border: none;
          width: 100%;
          height: 75px;
          font-family: $secondary_font;
          font-weight: 700;
          font-size: 24px;
          color: #0d5045;

          background: linear-gradient(94.67deg, #a5f822 1.65%, #00ff96 97.53%);
          border-radius: 10px;
        }
      }
    }
    .blank {
      border-bottom: 1px solid #aeaeae;
    }
    .description {
      margin-top: 1rem;
      margin-bottom: 3rem;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 40%;
        h3 {
          margin: 0;
          font-family: $secondary_font;
          font-weight: 700;
          font-size: 42px;
          color: #009541;
        }

        .details {
          margin-top: 1rem;
          p {
            margin: 0;
            font-family: $text_font;
            font-size: 20px;
            font-weight: 600;
            color: #664444;
          }

          table {
            ul {
              li {
                margin: 0;
                font-family: $text_font;
                font-size: 18px;
                color: #664444;
              }
            }
          }
        }
      }

      .blank-Y {
        height: 192px;
        border: 1px solid #d8e8df;
      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .header {
          display: flex;
          align-items: center;
          gap: 2rem;
          h3 {
            margin: 0;
            cursor: pointer;
            padding: 0.5rem 1rem;
            font-family: $secondary_font;
            font-size: 30px;
          }
          .deactive {
            color: #898989;
          }
          .active {
            color: #664444;
            border-bottom: 2px solid #664444;
          }
        }

        .details {
          margin-top: 2rem;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.5rem 0rem;

            h6 {
              margin: 0;
              font-family: $text_font;
              font-size: 26px;
              color: #664444;
            }
          }
          .items {
            padding: 1.5rem 0rem;
            display: flex;
            flex-direction: column;
            color: #664444;
            font-family: $text_font;
            h5 {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
            }
            p {
              margin: 0;
              font-size: 18px;
            }
          }
        }
      }
    }

    .similar-item {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      margin-bottom: 6rem;

      h3 {
        margin: 0;
        font-family: $secondary_font;
        font-weight: 700;
        font-size: 52px;
        color: #009541;
      }

      .list-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;

        .item {
          height: 500px;
          width: 22%;
          .img-box {
            width: 100%;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f4f4;
            border-radius: 10px;
            img {
              height: 310px;
            }
          }

          .details {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 500;
              font-size: 20px;
              color: #664444;
            }
            h6 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 400;
              font-size: 18px;
              color: #664444;
            }
          }

          p {
            margin-top: 4px;
            margin: 0;
            font-family: $text_font;
            font-weight: 400;
            font-size: 18px;
            color: #664444;
          }

          .button {
            display: none;
            text-decoration: none;
            border: none;
            width: 100%;
            height: 60px;
            background: linear-gradient(
              94.67deg,
              #a5f822 1.65%,
              #00ff96 97.53%
            );
            border-radius: 5px;
            font-family: $secondary_font;
            font-weight: 700;
            font-size: 24px;
            color: #0d5045;
          }
        }
        .item:hover {
          .button {
            margin-top: -6px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            visibility: hidden;
          }
        }
      }

      .load-more {
        margin-top: 1rem;
        border: none;
        background: none;
        width: 308px;
        height: 73px;
        font-family: $secondary_font;
        font-weight: 700;
        font-size: 18px;
        color: #0d5045;
        border: 1px solid #0d5045;
        border-radius: 16px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .product-details-components {
    .product-details-container {
      .hero-section {
        // border: 1px solid red;
        margin-top: 1.5rem;
        width: 80%;
        .left {
          height: 550px;
        }
        .right {
          .header {
            h3 {
              font-size: 38px;
              span {
                font-size: 26px;
              }
            }
            h6 {
              font-size: 22px;
            }
          }

          .details {
            .heading-item{
              h6{
                font-size: 20px;
              }
            }
            .item {
              padding: 0.5rem 0;
              p {
                font-size: 20px;
              }
            }
          }

          button {
            height: 70px;
            font-size: 24px;
          }
        }
      }
      .similar-item {
        h3 {
          font-size: 40px;
        }
        .list-items {
          gap: 30px;
          .item {
            height: 420px;
            .img-box {
              padding: 1.5rem;
              img {
                height: 260px;
              }
            }

            .details {
              margin-top: 16px;
              .name {
                font-size: 16px;
              }
              .tag {
                font-size: 14px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 60px;
              font-size: 20px;
            }
          }
        }
        .load-more {
          width: 300px;
          height: 70px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .product-details-components {
    .product-details-container {
      .hero-section {
        margin-top: 1.5rem;
        .left {
          height: 450px;
        }
        .right {
          .header {
            h3 {
              font-size: 30px;
              span {
                font-size: 20px;
              }
            }
            h6 {
              font-size: 18px;
            }
          }

          .details {
            margin-top: 2rem;
            .item {
              p {
                font-size: 20px;
              }

              h6 {
                font-size: 20px;
              }
            }
          }

          button {
            height: 60px;
            font-size: 20px;
          }
        }
      }
      .similar-item {
        h3 {
          font-size: 30px;
        }
        .list-items {
          .item {
            width: 30%;
            height: 450px;
            .img-box {
              padding: 2rem;
              img {
                height: 270px;
              }
            }

            .details {
              margin-top: 16px;
              .name {
                font-size: 16px;
              }
              .tag {
                font-size: 14px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 60px;
              font-size: 20px;
            }
          }
        }
        .load-more {
          width: 300px;
          height: 70px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .product-details-components {
    .product-details-container {
      .hero-section {
        margin-top: 1.5rem;
        .left {
          height: 400px;
        }
        .right {
          gap: 0;
          .header {
            h3 {
              font-size: 26px;
              span {
                font-size: 18px;
              }
            }
            h6 {
              font-size: 16px;
            }
          }

          .details {
            .heading-item{
              padding: 0.5rem 0;
              h6{
                font-size: 18px;
              }
            }
            .item {
              p {
                font-size: 18px;
              }
            }
          }

          button {
            height: 60px;
            font-size: 18px;
          }
        }
      }
      .similar-item {
        h3 {
          font-size: 26px;
        }
        .list-items {
          .item {
            width: 30%;
            height: 440px;
            .button {
              height: 50px;
              font-size: 18px;
            }
          }
        }
        .load-more {
          height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 925px) {
  .product-details-components {
    .product-details-container {
      .hero-section {
        .left {
          height: 350px;
        }
        .right {
          gap: 0;
          .header {
            gap: 0.2rem;
            h3 {
              font-size: 18px;
              span {
                font-size: 16px;
              }
            }
            h6 {
              font-size: 14px;
            }
          }

          .details {
            margin-top: 0.5rem;
            .heading-item{
              padding: 0.3rem 0;
              h6{
                font-size: 16px;
              }
            }
            .item {
              p {
                font-size: 16px;
              }
            }
          }

          button {
            height: 50px;
            font-size: 18px;
          }
        }
      }
      .similar-item {
        h3 {
          font-size: 20px;
        }
        .list-items {
          .item {
            width: 43%;
            height: 370px;
            .img-box {
              padding: 2rem;
              img {
                height: 210px;
              }
            }

            .details {
              margin-top: 8px;
              .name {
                font-size: 14px;
              }
              .tag {
                font-size: 12px;
              }
            }

            .para {
              font-size: 12px;
            }

            .button {
              height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-details-components {
    .product-details-container {
      .hero-section {
        flex-direction: column;
        gap: 2rem;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
          gap: 1rem;
          .header {
            gap: 0.5rem;
            h3 {
              font-size: 26px;
              span {
                font-size: 18px;
              }
            }
            h6 {
              font-size: 16px;
            }
          }

          .details {
            .heading-item{
              padding: 1rem 0;
              h6{
                font-size: 18px;
              }
            }

            .item {
              p {
                font-size: 18px;
              }
            }
          }

          button {
            height: 60px;
            font-size: 18px;
          }
        }
      }
      .similar-item {
        h3 {
          font-size: 20px;
        }
        .list-items {
          gap: 26px;
          .item {
            width: 45%;
            height: 370px;
            .para {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .product-details-components {
    .product-details-container {
      .similar-item {
        .list-items {
          gap: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .product-details-components {
    .product-details-container {
      .similar-item {
        h3 {
          font-size: 26px;
        }
        .list-items {
          gap: 50px;
          .item {
            width: 100%;
            height: 420px;
            .img-box {
              padding: 1.5rem;
              img {
                height: 260px;
              }
            }

            .details {
              margin-top: 16px;
              .name {
                font-size: 16px;
              }
              .tag {
                font-size: 14px;
              }
            }

            .para {
              font-size: 14px;
            }

            .button {
              height: 60px;
              font-size: 20px;
            }
          }
        }
        .load-more {
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }
}
