@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$primary_color: #0d5045;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;
$text_font: "DM Sans", sans-serif;

.footer-components {
  width: 100%;
  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .background-img {
      width: 100%;
    }

    .footer-items {
      width: 80%;
      top: 9rem;
      position: absolute;
      display: flex;
      color: $white_color;

      .box-1 {
        width: 27%;
        .logos {
          height: 88px;
        }
        .address {
          margin: 0;
          padding: 8px;
          font-family: $text_font;
          font-weight: 400;
          font-size: 18px;
          text-transform: capitalize;
          color: $white_color;
        }
      }
      .box-2 {
        width: 73%;
        display: flex;
        justify-content: space-between;
        .box-item {
          .head {
            margin: 0;
            font-family: $secondary_font;
            font-weight: 400;
            font-size: 26px;
            line-height: 130%;
            color: $white_color;
          }
          .body {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            .links {
              padding: 8px 0px;
              text-decoration: none;
              font-family: $text_font;
              font-weight: 400;
              font-size: 18px;
              color: $white_color;
            }
          }
        }
      }
    }

    .footer-tag-line {
      width: 80%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;

      .tag {
        font-family: $text_font;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: $white_color;

        .made-by {
          text-decoration: none;
          font-family: $text_font;
          font-weight: 400;
          font-size: 20px;
          color: $white_color;
        }
      }
    }

    .back-to-top {
      position: absolute;
      bottom: 20px;
      display: flex;
      gap: 1rem;

      .arrow-icon {
        height: 30px;
      }
      .top-btn {
        text-decoration: none;
        padding: 8px 0px;
        font-family: $secondary_font;
        font-weight: 400;
        font-size: 26px;
        line-height: 130%;
        letter-spacing: 0.02rem;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .footer-components {
    .footer-container {
      .footer-items {
        top: 7rem;
        .box-1 {
          width: 25%;
          .logos {
            height: 75px;
          }
          .address {
            font-size: 16px;
          }
        }
        .box-2 {
          width: 75%;
          .box-item {
            .head {
              font-size: 18px;
            }
            .body {
              .links {
                font-size: 16px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        .tag {
          font-size: 16px;
          .made-by {
            font-size: 18px;
          }
        }
      }

      .back-to-top {
        bottom: 5px;
        .arrow-icon {
          height: 26px;
        }
        .top-btn {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer-components {
    .footer-container {
      .footer-items {
        top: 6rem;
        .box-1 {
          .logos {
            height: 70px;
          }
          .address {
            font-size: 14px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 16px;
            }
            .body {
              .links {
                font-size: 14px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        .tag {
          font-size: 14px;
          .made-by {
            font-size: 16px;
          }
        }
      }

      .back-to-top {
        .arrow-icon {
          height: 20px;
        }
        .top-btn {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .footer-components {
    .footer-container {
      .footer-items {
        top: 4rem;
        .box-1 {
          .logos {
            height: 60px;
          }
          .address {
            font-size: 12px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 14px;
            }
            .body {
              .links {
                font-size: 12px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        .tag {
          font-size: 12px;
          .made-by {
            font-size: 14px;
          }
        }
      }

      .back-to-top {
        .arrow-icon {
          height: 16px;
        }
        .top-btn {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .footer-components {
    .footer-container {
      .footer-items {
        top: 3rem;
        .box-1 {
          .logos {
            height: 50px;
          }
          .address {
            font-size: 10px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 12px;
            }
            .body {
              .links {
                font-size: 10px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        .tag {
          font-size: 10px;
          .made-by {
            font-size: 12px;
          }
        }
      }

      .back-to-top {
        bottom: 3px;
        .arrow-icon {
          height: 20px;
        }
        .top-btn {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-components {
    .footer-container {
      .background-img {
        width: auto;
        height: 35rem;
      }

      .footer-items {
        width: 90%;
        top: 5rem;
        flex-direction: column-reverse;
        .box-1 {
          width: auto;
          margin-top: -8rem;
          text-align: right;
          .logos {
            height: 80px;
          }
          .address {
            font-size: 16px;
          }
        }
        .box-2 {
          width: 100%;
          flex-wrap: wrap;
          gap: 1rem;
          .box-item {
            .head {
              font-size: 18px;
            }
            .body {
              .links {
                font-size: 16px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        width: 95%;
        .tag {
          font-size: 12px;
          .made-by {
            font-size: 14px;
          }
        }
      }

      .back-to-top {
        bottom: 10px;
        .arrow-icon {
          height: 16px;
        }
        .top-btn {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .footer-components {
    .footer-container {
      .background-img {
        height: 31rem;
      }
      .footer-items {
        .box-1 {
          .logos {
            height: 60px;
          }
          .address {
            font-size: 14px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 16px;
            }
            .body {
              .links {
                font-size: 14px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        width: 95%;
        .tag {
          font-size: 14px;
          .made-by {
            font-size: 15px;
          }
        }
      }

      .back-to-top {
        bottom: 40px;
        .arrow-icon {
          height: 14px;
        }
        .top-btn {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .footer-components {
    .footer-container {
      .background-img {
        height: 27rem;
      }
      .footer-items {
        top: 3rem;
        .box-1 {
          margin-top: -7rem;
          .logos {
            height: 50px;
          }
          .address {
            font-size: 12px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 14px;
            }
            .body {
              .links {
                font-size: 12px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        width: 95%;
        .tag {
          font-size: 13px;
          .made-by {
            font-size: 14px;
          }
        }
      }

      .back-to-top {
        bottom: 40px;
        .arrow-icon {
          height: 13px;
        }
        .top-btn {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .footer-components {
    .footer-container {
      .background-img {
        height: 25rem;
      }
      .footer-items {
        // top: 3rem;
        .box-1 {
          margin-top: -6rem;
          .logos {
            height: 40px;
          }
          .address {
            font-size: 10px;
          }
        }
        .box-2 {
          .box-item {
            .head {
              font-size: 12px;
            }
            .body {
              .links {
                font-size: 10px;
              }
            }
          }
        }
      }

      .footer-tag-line {
        width: 95%;
        .tag {
          font-size: 12px;
          .made-by {
            font-size: 13px;
          }
        }
      }

      .back-to-top {
        bottom: 30px;
        .arrow-icon {
          height: 12px;
        }
        .top-btn {
          font-size: 12px;
        }
      }
    }
  }
}
