@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");

$primary_color: #003c32;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;

$text-color: #a5f722;
$text-black: #202020;
$button-color: #007aff;
$error-color: red;

// Navbar-Components============================
.user-navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 99px;
  display: flex;
  justify-content: center !important;
  background-color: $white_color;
  z-index: 10000;

  .nav-container-for-web {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .brand {
      .brand-logo {
        height: 73px;
      }
    }

    .menus {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;

      .links {
        text-decoration: none;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $primary_color;
        font-family: $secondary_font;
        transition: 0.5s;

        .icons {
          font-size: 1.5rem;
        }
      }

      .links:hover {
        // color: $secondary_color;
        transform: scale(1.1);
        transition: 0.5s;
      }

      .products {
        .options {
          display: none;
        }
      }
      .products:hover {
        position: relative;
        .options {
          display: block;
          top: 0;
          left: -5px;
          padding: 0.5rem;
          position: absolute;
          background-color: $white_color;
          z-index: 1000;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .links {
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .btns {
      .contact-btn {
        font-family: $secondary_font;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        width: 201px;
        height: 60px;
        color: $primary_color;
        background-color: $white_color;
        border: 2px solid $primary_color;
        border-radius: 30px;
        cursor: pointer;
        transition: 0.5s;
      }
      .contact-btn:hover {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
  .blank {
    width: 90%;
    top: 99px;
    position: absolute;
    border-bottom: 1px solid $secondary_color;
  }
  .for-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1440px) {
  .user-navbar {
    width: 100%;
    .nav-container-for-web {
      .menus {
        gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .user-navbar {
    border-bottom: 1px solid $secondary_color;
    .for-web {
      display: none !important;
    }
    .for-mobile {
      display: block !important;
    }
    .blank {
      display: none;
    }

    .nav-container-for-mobile {
      display: flex !important;
      justify-content: space-between !important;
      width: 100%;
      background-color: $white_color;
      padding: 0rem 2rem;

      .brand {
        .brand-logo {
          height: 70px;
        }
      }
      .toggole {
        border-color: $text-black;
      }
      .content2 {
        margin: 2rem;
        .menus-item {
          gap: 2rem;
          height: 55vh;
          .links {
            padding: 0.7rem;
            text-decoration: none;
            width: 100%;
            font-size: 1.125rem;
            font-family: $secondary_font;
            text-align: center;
            border: 1px solid $primary_color;
            color: $primary_color;
            border-radius: 4px;
          }

          .links:hover {
            color: $white_color;
          }

          .products {
            p {
              margin: 0;
            }
            .options {
              display: none;
            }
          }
          .products:hover {
            position: relative;
            .options {
              width: 100%;
              display: block;
              top: 0;
              position: absolute;
              background-color: $primary_color;
              z-index: 1000;
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              gap: 32px;
            }
          }
        }
      }
    }
  }
}
