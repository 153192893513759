@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$primary_color: #0d5045;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;
$text_font: "DM Sans", sans-serif;

.aboutus-components {
  width: 100%;

  .about-container {
    margin-top: 99px;

    .top-div {
      height: auto;
      padding: 4rem 0rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary_color;

      .header-container {
        width: 80%;
        display: flex;
        align-items: center;

        .left {
          width: 50%;
          .items {
            display: flex;
            flex-direction: column;
            gap: 26px;
            h3 {
              font-family: $secondary_font;
              font-weight: 700;
              font-size: 64px;
              color: $white_color;
            }
            .links {
              display: flex;
              align-items: center;
              gap: 8px;
              .icons {
                font-size: 24px;
                color: $white_color;
              }
              p {
                margin: 0;
                font-family: $text_font;
                font-size: 18px;
                color: $white_color;
              }
            }
          }
        }
        .right {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
    }

    .mid-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .background {
        width: 100%;
      }
      .mid-containet {
        position: absolute;
        width: 80%;
        display: flex;
        align-items: center;
        .left {
          width: 50%;
          .heading {
            font-family: $secondary_font;
            font-weight: 700;
            font-size: 52px;
            line-height: 62px;
            color: #009541;
          }
          .tags {
            font-family: $text_font;
            font-weight: 700;
            font-size: 36px;
            line-height: 62px;
            color: #009541;
          }
          .para {
            width: 80%;
            font-family: $text_font;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #664444;
          }
          .button {
            margin-top: 3rem;
            border: none;
            width: 240px;
            height: 75px;
            font-family: $secondary_font;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: -0.05em;
            color: $primary_color;
            background: linear-gradient(92deg, #a5f822 25.91%, #00ff96 78.3%);
            border-radius: 10px;
          }
        }
      }
    }

    .last-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .container-box {
        margin-top: 4rem;
        margin-bottom: 8rem;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          height: 277px;
          width: 31%;
          padding: 1.5rem;
          background: $white_color;
          box-shadow: 0px 0px 35px rgba(6, 40, 21, 0.1);
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .header {
            display: flex;
            align-items: center;
            gap: 1rem;
            img {
              width: 56px;
              height: 56px;
            }

            h3 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 700;
              font-size: 24px;
              color: $primary_color;
            }
          }
          .para {
            margin: 0;
            text-align: center;
            font-family: $text_font;
            font-weight: 500;
            font-size: 18px;
            color: #664444;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .aboutus-components {
    .about-container {
      .top-div {
        .header-container {
          width: 80%;
          .left {
            .items {
              gap: 20px;
              h3 {
                font-size: 54px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .background {
          width: 100%;
        }
        .mid-containet {
          width: 80%;
          .left {
            width: 50%;
            .heading {
              font-size: 40px;
            }
            .tags {
              font-size: 30px;
            }
            .button {
              margin-top: 2rem;
            }
          }
        }
      }

      .last-container {
        .container-box {
          margin-top: 4rem;
          margin-bottom: 8rem;
          width: 80%;
          .item {
            height: 17rem;
            width: 31%;
            padding: 1.5rem;
            .header {
              gap: 1rem;
              img {
                width: 40px;
                height: 40px;
              }

              h3 {
                font-size: 20px;
              }
            }
            .para {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .aboutus-components {
    .about-container {
      .top-div {
        .header-container {
          width: 80%;
          .left {
            width: 50%;
            .items {
              gap: 16px;
              h3 {
                font-size: 40px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .mid-containet {
          width: 80%;
          .left {
            height: auto;
            gap: 0px;
            .heading {
              margin: 0;
              font-size: 30px;
            }
            .tags {
              margin: 0;
              font-size: 26px;
            }
            .para {
              width: 80%;
              font-size: 16px;
            }
            .button {
              margin-top: 0rem;
              height: 70px;
              font-size: 22px;
            }
          }
        }
      }

      .last-container {
        .container-box {
          width: 80%;
          .item {
            height: 17rem;
            padding: 1.5rem;
            .header {
              gap: 1rem;
              img {
                width: 35px;
                height: 35px;
              }

              h3 {
                font-size: 16px;
              }
            }
            .para {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .aboutus-components {
    .about-container {
      .top-div {
        .header-container {
          width: 85%;
          .left {
            width: 50%;
            .items {
              gap: 8px;
              h3 {
                font-size: 35px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .mid-containet {
          width: 85%;
          .left {
            .heading {
              font-size: 26px;
            }
            .tags {
              margin-top: -2rem;
              font-size: 18px;
            }
            .para {
              margin: 0;
              width: 90%;
              font-size: 16px;
            }
            .button {
              margin-top: 1rem;
              height: 60px;
              font-size: 18px;
            }
          }
        }
      }

      .last-container {
        .container-box {
          width: 90%;
          .item {
            width: 32%;
            height: 19rem;
            padding: 1.5rem;
            .header {
              gap: 1rem;
              img {
                width: 35px;
                height: 35px;
              }

              h3 {
                font-size: 16px;
              }
            }
            .para {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 925px) {
  .aboutus-components {
    .about-container {
      .top-div {
        .header-container {
          .left {
            .items {
              gap: 8px;
              h3 {
                font-size: 30px;
              }
              .links {
                .icons {
                  font-size: 20px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .mid-containet {
          width: 90%;
          .left {
            .heading {
              font-size: 20px;
            }
            .tags {
              margin-top: -2rem;
              font-size: 16px;
            }
            .para {
              width: 90%;
              font-size: 14px;
            }
            .button {
              margin-top: 1rem;
              height: 50px;
              width: 200px;
              font-size: 16px;
            }
          }
        }
      }

      .last-container {
        .container-box {
          width: 90%;
          .item {
            width: 32%;
            // height: 19rem;
            // padding: 1.5rem;
            .header {
              gap: 0.5rem;
              img {
                width: 30px;
                height: 30px;
              }

              h3 {
                font-size: 14px;
              }
            }
            .para {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .aboutus-components {
    .about-container {
      .top-div {
        padding: 2rem 0rem;
        .header-container {
          .left {
            .items {
              gap: 8px;
              h3 {
                font-size: 26px;
              }
              .links {
                .icons {
                  font-size: 20px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .mid-containet {
          width: 90%;
          .left {
            .heading {
              font-size: 16px;
              display: flex;
              align-items: center;
            }
            .tags {
              margin: 0;
              margin-top: -2.5rem;
              font-size: 14px;
              // height: 50px;
              display: flex;
              align-items: center;
            }
            .para {
              width: 90%;
              font-size: 10px;
            }
            .paras {
              margin-top: -1rem !important;
            }
            .button {
              margin-top: 1rem;
              height: 40px;
              width: 150px;
              font-size: 14px;
            }
          }
        }
      }

      .last-container {
        .container-box {
          width: 90%;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2rem;
          .item {
            width: 47%;
            .header {
              gap: 1rem;
              img {
                width: 40px;
                height: 40px;
              }

              h3 {
                font-size: 16px;
              }
            }
            .para {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .aboutus-components {
    .about-container {
      .top-div {
        padding: 2rem 0rem;
        .header-container {
          .left {
            .items {
              gap: 8px;
              h3 {
                font-size: 20px;
              }
              .links {
                .icons {
                  font-size: 16px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .mid-div {
        .mid-containet {
          width: 90%;
          .left {
            .heading {
              font-size: 14px;
            }
            .para {
              width: 90%;
            }
            .button {
              margin-top: 0.5rem;
              height: 40px;
              width: 140px;
              font-size: 12px;
            }
          }
        }
      }

      .last-container {
        .container-box {
          width: 90%;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2rem;
          .item {
            width: 80%;
            .header {
              gap: 1rem;
              img {
                width: 60px;
                height: 60px;
              }

              h3 {
                font-size: 20px;
              }
            }
            .para {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 555px) {
  .aboutus-components {
    .about-container {
      .mid-div {
        .mid-containet {
          .left {
            width: 60%;
            .para {
              width: 80%;
            }
          }
        }
        .background{
          height: 20rem;
        }
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .aboutus-components {
    .about-container {
      .mid-div {
        .mid-containet {
          .left {
            width: 70%;
            .para {
              width: 70%;
            }
          }
        }
        .background{
          height: 22rem;
        }
      }
      .last-container {
        .container-box {
          width: 90%;
          .item {
            width: 90%;
          }
        }
      }
    }
  }
}