@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

$primary_color: #0d5045;
$secondary_color: #a5f722;
$border_color: #22a38e;
$white_color: #ffffff;
$secondary_font: "Unbounded", cursive;
$text_font: "DM Sans", sans-serif;

.contact-components {
  width: 100%;

  .contact-container {
    margin-top: 99px;

    .top-div {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: $primary_color;
      position: relative;
      .background-top {
        width: 100%;
      }

      .header-container {
        position: absolute;
        width: 80%;
        display: flex;
        margin-top: 5rem;
        .left {
          width: 50%;
          .items {
            display: flex;
            flex-direction: column;
            gap: 26px;
            h3 {
              font-family: $secondary_font;
              font-weight: 700;
              font-size: 64px;
              color: $white_color;
            }
            .links {
              display: flex;
              align-items: center;
              gap: 8px;
              .icons {
                font-size: 24px;
                color: $white_color;
              }
              p {
                margin: 0;
                font-family: $text_font;
                font-size: 18px;
                color: $white_color;
              }
            }
          }
        }
        .right {
          width: 50%;
        }
      }
    }

    .contact-mid-div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8rem;
      margin-bottom: 8rem;
      .content {
        width: 80%;
        display: flex;
        align-items: center;
        .left {
          width: 50%;
          position: relative;
          .contactus {
            position: absolute;
            right: 0;
            top: 8rem;
          }

          .heading {
            margin: 0;
            font-family: $secondary_font;
            font-weight: 700;
            font-size: 48px;
            color: #009541;
          }
          .contact-details {
            margin-top: 1rem;
            background-color: #fafff1;
            border-radius: 15px;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            border-bottom: 8px solid #a5f723;
            .item {
              display: flex;
              gap: 1.5rem;
              img {
                height: 60px;
                width: 60px;
              }
              .details {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                h3 {
                  margin: 0;
                  font-family: $secondary_font;
                  font-weight: 600;
                  font-size: 26px;
                  color: #009541;
                }

                .point {
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  p {
                    margin: 0;
                    font-family: $text_font;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    text-transform: capitalize;
                    color: #664444;
                  }
                }
              }
            }
          }
        }

        .right {
          width: 50%;
          .form-container {
            margin-top: 4rem;
            padding: 2rem;
            background: $white_color;
            box-shadow: 0px 0px 35px rgba(6, 40, 21, 0.1);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
              margin: 0;
              font-family: $secondary_font;
              font-weight: 900;
              font-size: 36px;
              color: #005f16;
            }
            p {
              margin: 0;
              width: 80%;
              text-align: center;
              font-family: $text_font;
              font-weight: 500;
              font-size: 18px;
              color: #664444;
            }

            .form {
              margin-top: 1.5rem;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 2rem;
              .input-grup {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .input-box {
                  height: 4rem;
                  width: 47%;
                  input {
                    width: 100%;
                    padding: 0px 16px;
                    border: none;
                    height:100%;
                    background: #f5f5f7;
                    border-radius: 4px;
                    font-family: $text_font;
                    font-size: 18px;
                    color: #7e7c87;
                  }
                }
              }
              .input-box-outer {
                height: 4rem;
                input {
                  width: 100%;
                  padding: 0px 16px;
                  border: none;
                  height: 100%;
                  background: #f5f5f7;
                  border-radius: 4px;
                  font-family: $text_font;
                  font-size: 18px;
                  color: #7e7c87;
                }
              }

              .textarea-box {
                height: 13rem;
                textarea {
                  width: 100%;
                  padding: 16px;
                  min-height: 13rem;
                  max-height: 13rem;
                  border: none;
                  background: #f5f5f7;
                  border-radius: 4px;
                  font-family: $text_font;
                  font-size: 18px;
                  color: #7e7c87;
                }
              }
              button {
                border: none;
                height: 75px;
                width: 100%;
                background: linear-gradient(
                  92deg,
                  #a5f822 25.91%,
                  #00ff96 78.3%
                );
                border-radius: 10px;
                font-family: $secondary_font;
                font-weight: 700;
                font-size: 24px;
                text-transform: uppercase;
                color: #0d5045;
              }

              input:focus {
                outline: none;
              }
              .number::-webkit-inner-spin-button {
                display: none;
              }
              textarea:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          width: 80%;
          margin-top: 6rem;
          .left {
            width: 50%;
            .items {
              gap: 20px;
              h3 {
                font-size: 54px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 8rem;
        margin-bottom: 8rem;
        .content {
          width: 80%;

          .left {
            .contactus {
              top: 6rem;
            }

            .heading {
              font-size: 40px;
              img{
                width: 5rem;
              }
            }
            .contact-details {
              padding: 3rem;
              gap: 2rem;
              .item {
                gap: 1.5rem;
                img {
                  height: 50px;
                  width: 50px;
                }
                .details {
                  h3 {
                    font-size: 22px;
                  }

                  .point {
                    p {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }

          .right {
            .form-container {
              margin-top: 4rem;
              padding: 2rem;
              h3 {
                font-size: 30px;
              }
              p {
                width: 85%;
                font-size: 16px;
              }

              .form {
                gap: 1.5rem;
                .input-grup {
                  .input-box {
                    height: 3.5rem;
                  }
                }
                .input-box-outer {
                  height: 3.5rem;
                }

                .textarea-box {
                  height: 12rem;
                  textarea {
                    min-height: 12rem;
                    max-height: 12rem;
                  }
                }
                button {
                  height: 70px;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          width: 80%;
          margin-top: 5rem;
          .left {
            width: 50%;
            .items {
              gap: 16px;
              h3 {
                font-size: 40px;
              }
              .links {
                .icons {
                  font-size: 24px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 8rem;
        margin-bottom: 8rem;
        .content {
          width: 80%;
          .left {
            .contactus {
              top: 4rem;
              width: 4rem;
            }

            .heading {
              font-size: 30px;
              img{
                width: 4rem;
              }
            }
            .contact-details {
              padding: 1rem;
              gap: 1.5rem;
              .item {
                gap: 1.5rem;
                img {
                  height: 45px;
                  width: 45px;
                }
                .details {
                  h3 {
                    font-size: 20px;
                  }

                  .point {
                    p {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }

          .right {
            .form-container {
              margin-top: 0rem;
              padding: 2rem;
              h3 {
                font-size: 26px;
              }
              p {
                width: 100%;
                font-size: 16px;
              }

              .form {
                gap: 1.5rem;
                .input-grup {
                  .input-box {
                    height: 3rem;
                  }
                }
                .input-box-outer {
                  height: 3rem;
                }

                .textarea-box {
                  height: 9rem;
                  textarea {
                    min-height: 9rem;
                    max-height: 9rem;
                  }
                }
                button {
                  height: 60px;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          width: 80%;
          margin-top: 4rem;
          .left {
            width: 50%;
            .items {
              gap: 8px;
              h3 {
                font-size: 30px;
              }
              .links {
                .icons {
                  font-size: 20px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 6rem;
        margin-bottom: 6rem;
        .content {
          width: 85%;
          .left {
            .contactus {
              top: 4rem;
              width: 3.5rem;
            }

            .heading {
              font-size: 26px;
              img{
                width: 3rem;
              }
            }
            .contact-details {
              padding: 1rem;
              gap: 1.5rem;
              .item {
                gap: 1rem;
                img {
                  height: 40px;
                  width: 40px;
                }
                .details {
                  h3 {
                    font-size: 18px;
                  }

                  .point {
                    p {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }

          .right {
            .form-container {
              margin-top: 0rem;
              padding: 2rem;
              h3 {
                font-size: 20px;
              }
              p {
                font-size: 14px;
              }

              .form {
                gap: 1.3rem;
                .input-grup {
                  .input-box {
                    height: 3rem;
                  }
                }
                .input-box-outer {
                  height: 3rem;
                }

                .textarea-box {
                  height: 9rem;
                  textarea {
                    min-height: 9rem;
                    max-height: 9rem;
                  }
                }
                button {
                  height: 50px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 925px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          width: 80%;
          margin-top: 4rem;
          .left {
            width: 50%;
            .items {
              gap: 8px;
              h3 {
                font-size: 26px;
              }
              .links {
                .icons {
                  font-size: 20px;
                }
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 6rem;
        margin-bottom: 6rem;
        .content {
          width: 85%;
          .left {
            .contactus {
              top: 4.5rem;
              width: 3rem;
            }

            .heading {
              font-size: 26px;
              img{
                width: 3rem;
              }
            }
            .contact-details {
              gap: 1rem;
              .item {
                gap: 0.5rem;
                img {
                  height: 40px;
                  width: 40px;
                }
                .details {
                  h3 {
                    font-size: 16px;
                  }

                  .point {
                    p {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }

          .right {
            .form-container {
              padding: 1rem;
              h3 {
                font-size: 18px;
              }
              p {
                font-size: 14px;
              }

              .form {
                gap: 1rem;
                .textarea-box {
                  height: 8rem;
                  textarea {
                    min-height: 8rem;
                    max-height: 8rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          width: 85%;
          margin-top: 3rem;
          .left {
            .items {
              h3 {
                font-size: 20px;
              }
              .links {
                .icons {
                  font-size: 18px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 4rem;
        margin-bottom: 4rem;
        .content {
          width: 80%;
          flex-direction: column;
          gap: 4rem;
          .left {
            width: 100%;
            text-align: center;
            .contactus {
              top: 30%;
              width: 3rem;
            }

            .heading {
              font-size: 30px;
              img{
                width: 4rem;
              }
            }
            .contact-details {
              padding: 2rem;
              gap: 1.5rem;
              .item {
                gap: 1rem;
                text-align: left;
                img {
                  height: 40px;
                  width: 40px;
                }
                .details {
                  h3 {
                    font-size: 18px;
                  }

                  .point {
                    p {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }

          .right {
            width: 100%;
            .form-container {
              margin-top: 0rem;
              padding: 2rem;
              h3 {
                font-size: 26px;
              }
              p {
                font-size: 16px;
              }

              .form {
                gap: 1.5rem;
                .input-grup {
                  .input-box {
                    height: 3rem;
                  }
                }
                .input-box-outer {
                  height: 3rem;
                }

                .textarea-box {
                  height: 10rem;
                  textarea {
                    min-height: 10rem;
                    max-height: 10rem;
                  }
                }
                button {
                  height: 60px;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .contact-components {
    .contact-container {
      .top-div {
        .header-container {
          margin-top: 2rem;
          .left {
            .items {
              gap: 0;
              h3 {
                font-size: 18px;
              }
              .links {
                .icons {
                  font-size: 16px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .contact-mid-div {
        margin-top: 3rem;
        margin-bottom: 3rem;
        .content {
          width: 90%;
          .left {
            .contactus {
              top: 30%;
              width: 2.5rem;
            }

            .heading {
              font-size: 20px;
              img{
                width: 2.5rem;
              }
            }
            .contact-details {
              padding: 1.5rem;
              gap: 1.5rem;
              .item {
                gap: 1rem;
                img {
                  height: 40px;
                  width: 40px;
                }
                .details {
                  gap: 0.5rem;
                  h3 {
                    font-size: 16px;
                  }

                  .point {
                    p {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }

          .right {
            .form-container {
              padding: 1.5rem;
              h3 {
                font-size: 20px;
              }
              p {
                font-size: 14px;
              }

              .form {
                button {
                  height: 50px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}