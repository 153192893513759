$brand-name-clr2: #0066b3;

.page_404 {
    padding: 2.5rem 0;
    background: #fff;
    font-family: 'Arvo', serif;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        .row {
            .col-sm-12 {
                display: flex;
                align-items: center;
                justify-content: center;
                .col-sm-offset-1 {
                    .four_zero_four_bg {
                        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
                        height: 40rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        h1 {
                            font-size: 5rem;
                            font-weight: bold;
                            margin: 0;
                        }
                    }

                    .contant_box_404 {
                        margin-top: -9.375rem;
                        .link_404 {
                            color: #fff !important;
                            padding: 0.625rem 1.25rem;
                            background: $brand-name-clr2;
                            margin: 1.25rem 0;
                            display: inline-block;
                            text-decoration: none;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

}